import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from 'za-ui-controls';
import { environment } from 'src/environments/environment';
import { IRegion } from './interfaces/region.interface';
import { ISiteDetails } from 'src/app/types/site-details.interface';

@Injectable({
  providedIn: 'root',
})
export class SiteManagementService {
  constructor(
    private httpService: HttpClientService,
  ) {}

  getAllSites(): Observable<ISiteDetails[]> {
    return this.httpService.get(environment.apiBaseUrl + '/site/list');
  }

  getUserSites(): Observable<ISiteDetails[]> {
    return this.httpService.get(environment.apiBaseUrl + '/site/userList');
  }

  getSiteDetails(siteId: number): Observable<ISiteDetails> {
    return this.httpService.get(environment.apiBaseUrl + '/site/?siteId=' + siteId);
  }

  getRoadmapCompleteSites(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/site/roadmapCompleteList');
  }

  getStateByCountry(countryId: number): Observable<IRegion[]> {
    return this.httpService.get(
      environment.apiBaseUrl + '/common/data/states?countryId=' + countryId,
    );
  }

  saveSiteDetails(siteDetailObj: object): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/site/', siteDetailObj);
  }

  deleteSite(siteId: number): Observable<any> {
    return this.httpService.delete(environment.apiBaseUrl + '/site/?siteId=' + siteId);
  }

  getSiteLimit(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/common/sitelimit');
  }

  getSiteInvoiceAction(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/track/latestAction?siteId=' + siteId);
  }

  getRoadMapGenerationTime(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/generatedTime/' + siteId);
  }
}
