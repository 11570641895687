import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  Renderer2,
  EmbeddedViewRef,
  AfterViewInit,
} from '@angular/core';
import { UserService } from './user.service';
import { UserRole } from '../types/user-role.enum';

@Directive({
  selector: '[s3HasRole]',
})
export class HasRoleDirective implements OnInit, AfterViewInit {
  private _s3HasRole: UserRole[] = [];
  private hasRequiredRole: boolean = false;

  @Input()
  set s3HasRole(value: UserRole[]) {
    this._s3HasRole = value;
  }

  private _site: string | number | null | undefined = undefined;
  @Input()
  set s3HasRoleSite(value: string | number | null | undefined) {
    this._site = value;
  }

  private _isDisabled: boolean = false;
  @Input()
  set s3HasRoleCanDisable(types: boolean) {
    this._isDisabled = types;
  }

  private hasView = false;
  private viewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    private userService: UserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this._s3HasRole.length) {
      const siteIds = this._site ? [this._site] : undefined;
      this.userService.userHasPermission(this._s3HasRole, siteIds).subscribe(hasRequiredRole => {
        this.hasRequiredRole = hasRequiredRole;
        if (hasRequiredRole && !this.hasView) {
          this.addView();
        } else if (!hasRequiredRole && this.hasView) {
          this.removeView();
        } else if (!hasRequiredRole && !this.hasView && this._isDisabled) {
          this.addView();
          this.applyDisabledState();
        }
      });
    } else if (!this.hasView) {
      this.addView();
    }
  }

  ngAfterViewInit(): void {
    // Apply styles after the view has initialized
    if (this.hasView && !this.hasRequiredRole) {
      this.applyDisabledState();
    }
  }

  private addView(): void {
    this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }

  private removeView(): void {
    this.viewContainer.clear();
    this.hasView = false;
    this.viewRef = null;
  }

  private applyDisabledState(): void {
    if (this._isDisabled && this.hasView) {
      if (this.viewRef) {
        const elements = this.viewRef.rootNodes;
        elements.forEach((element: HTMLElement) => {
          this.applyStylesRecursively(element);
          this.renderer.setStyle(element, 'pointer-events', 'none');
        });
      }
    }
  }

  private applyStylesRecursively(element: HTMLElement): void {
    this.renderer.setStyle(element, 'color', 'var(--s3-color-default-25)');
    Array.from(element.children).forEach(child => {
      this.applyStylesRecursively(child as HTMLElement);
    });
  }
}
