import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace',
})
export class StringReplacePipe implements PipeTransform {
  transform(value: string, oldValue: string, newValue: string): string {
    if (!value || !oldValue) {
      return value;
    }

    return value.replace(oldValue, newValue);
  }
}
