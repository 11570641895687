export function clone<T extends object>(t: T): T {
  const cloneObject = (obj: any): any => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    // Handle Array
    if (Array.isArray(obj)) {
      return obj.map(cloneObject);
    }

    // Handle Object
    if (obj instanceof Object) {
      const clonedObj = Object.create(Object.getPrototypeOf(obj));
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (typeof obj[prop] === 'function') {
            clonedObj[prop] = obj[prop].bind(clonedObj);
          } else {
            clonedObj[prop] = cloneObject(obj[prop]);
          }
        }
      }
      return clonedObj;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  };
  if (hasCallbackFunctions(t)) return cloneObject(t);
  else return JSON.parse(JSON.stringify(t));
}

function hasCallbackFunctions(obj: any): boolean {
  // Basic type check to ensure obj is an object
  if (obj === null || typeof obj !== 'object') {
    return false;
  }

  // Check each property of the object
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (typeof obj[prop] === 'function') {
        return true; // Found a function, return true
      }

      // Recursively check nested objects
      if (typeof obj[prop] === 'object' && hasCallbackFunctions(obj[prop])) {
        return true;
      }
    }
  }

  return false; // No functions found
}

/**
 * Formats a currency and monetary value.
 * ```ts
 * formatMonetaryValue(100, '$') => "$100"
 * formatMonetaryValue(123.45, '$') => "$123.45"
 * formatMonetaryValue(100, '$', true) => "$100.00"
 * formatMonetaryValue(100, '$', true, 4) => "$100.0000"
 * formatMonetaryValue(123.45, '$', true, 2) => "$123.45"
 * formatMonetaryValue(123.45, '$', true, 4) => "$123.4500"
 * formatMonetaryValue(123.4567, '$', true, 2) => "$123.45"
 * ```
 *
 * @param amount Monetary value
 * @param currency Currency
 * @param forceDecimal Should decimals always be shown. Defaults to false. If the number is an integer and this is false, no decimals will be rendered. If the number is a Real number, it will be rendered with `decimalPlaces` decimal places.
 * @param decimalPlaces Number of decimal places to show, if decimals are shown. Defaults to 2.
 * @param currencyCode Currency ISO Code
 * @returns
 */
export function formatMonetaryValue(
  amount: number,
  currency = '$',
  currencyCode = 'usd',
  forceDecimal = false,
  decimalPlaces = 2,
): string {
  let value = '';
  if (forceDecimal) {
    value = amount.toFixed(decimalPlaces);
  } else {
    if (String(amount).indexOf('.') > 0) {
      value = amount.toFixed(decimalPlaces);
    } else {
      value = String(amount);
    }
  }
  return `${currency}${value}${' ' + currencyCode}`;
}
