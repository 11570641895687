import { NgModule } from '@angular/core';
import { UserService } from './user.service';
import { CommonService } from './common.service';
import { CompanyService } from './client.service';
import { UIControlsModule } from 'src/app/ui-controls/ui.controls.module';
import { SustainabilityCommonModule } from '../sustainability-common/sustainability.common.module';
import { SiteManagementService } from '../home/site-management/site-management.service';
import { AccessManagementService } from './access-management.service';
import { HasRoleDirective } from './role.directive';

@NgModule({
  imports: [UIControlsModule, SustainabilityCommonModule],
  declarations: [HasRoleDirective],
  exports: [HasRoleDirective],
  providers: [
    CommonService,
    CompanyService,
    UserService,
    SiteManagementService,
    AccessManagementService,
  ],
})
export class ApiModule {}
