import { Injectable } from '@angular/core';
import { WindowRefService } from './windowref.service';
import pageViewJson from './ga-pageview-metadata.json';
import { environment } from 'src/environments/environment';

interface routePageInfoMap {
  route: string;
  pageTitle: string;
  template: string;
  pageTopCategory: string | null;
  pageCategory: string | null;
  pageSubCategory: string | null;
  pageSubSubCategory: string | null;
}
@Injectable({
  providedIn: 'root',
})
export class GaDataLayerService {
  private window;
  private routeMap: routePageInfoMap[];

  constructor(_windowRef: WindowRefService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
    this.routeMap = pageViewJson;
  }

  pushPageView(route: string): void {
    const byRoute = this.matchRoute(this.routeMap, route);
    if (byRoute) {
      const evt = {
        event: 'page_view',
        digitalPlatform: this.window.location.hostname,
        digitalPlatformCountry: '',
        emailOptin: false,
        environment: environment.environmentName,
        language: this.window.navigator.language,
        fromWebView: false,
        pageCategory: byRoute.pageCategory,
        pageLanguage: this.window.navigator.language,
        pageSubCategory: byRoute.pageSubCategory,
        pageSubSubCategory: byRoute.pageSubSubCategory,
        pageTitle: byRoute.pageTitle,
        pageTopCategory: byRoute.pageTopCategory,
        template: byRoute.template,
        overallCompletion: 0,
      };
      this.pushDataLayerEvent(evt);
    }
  }

  matchRoute(routeMap: any, currentRoute: any): any {
    for (const routeObj of routeMap) {
      const routeSegments = routeObj.route.split('?');
      // handles path segments
      const routePathPattern = routeSegments[0].replace(/:\w+/g, '\\d+');
      // handles query params
      const routeQueryPattern = routeSegments[1]
        ? routeSegments[1].replace(/:\w+/g, '(?:[a-zA-Z0-9]+=[a-zA-Z0-9]+&?)+')
        : '';
      const pattern = routeQueryPattern
        ? `${routePathPattern}\\?${routeQueryPattern}`
        : routePathPattern;
      const regex = new RegExp(`^${pattern}$`);

      if (regex.test(currentRoute)) {
        return routeObj;
      }
    }

    return null;
  }

  pushCompanyProfileBuilderStep(stepNumber: number, stepName: string): void {
    const evt = {
      event: 'company_profile_builder',
      stepNumber: stepNumber,
      stepName: stepName,
    };
    this.pushDataLayerEvent(evt);
  }

  pushRoadmapBuilderStep(stepNumber: number, stepName: string): void {
    const evt = {
      event: 'roadmap_builder',
      stepNumber: stepNumber,
      stepName: stepName,
    };
    this.pushDataLayerEvent(evt);
  }

  pushAddSiteBuilderStep(stepNumber: number, stepName: string): void {
    const evt = {
      event: 'add_site_builder',
      stepNumber: stepNumber,
      stepName: stepName,
    };
    this.pushDataLayerEvent(evt);
  }

  pushBaselineSet(baselineYear: number): void {
    const evt = {
      event: 'set_baseline',
      baselineYear: baselineYear,
    };
    this.pushDataLayerEvent(evt);
  }

  pushProjectStatusChange(projectStatus: string, projectCategory: string, projectId: string): void {
    const evt = {
      event: 'project_status_change',
      projectStatus: projectStatus,
      projectCategory: projectCategory,
      projectId: projectId,
    };
    this.pushDataLayerEvent(evt);
  }

  pushSetTarget(targetType: string): void {
    const evt = {
      event: 'set_target',
      targetType: targetType,
    };
    this.pushDataLayerEvent(evt);
  }

  pushTargetReached(targetType: string): void {
    const evt = {
      event: 'target_reached',
      targetType: targetType,
    };
    this.pushDataLayerEvent(evt);
  }

  pushSiteCreated(siteType: string): void {
    const evt = {
      event: 'site_created',
      siteType: siteType,
    };
    this.pushDataLayerEvent(evt);
  }

  pushAddData(dataType: string, dataValue: string, startDate: string, endDate: string): void {
    const evt = {
      event: 'add_data',
      dataType: dataType,
      dataValue: dataValue,
      startDate: startDate,
      endDate: endDate,
    };
    this.pushDataLayerEvent(evt);
  }

  private pushDataLayerEvent(obj: object): void {
    if (obj) this.window.dataLayer.push(obj);
  }
}
