<s3-standalone-header></s3-standalone-header>
<div class="landing-body">
  <div class="landing-content">
    <img class="mountain-banner" src="assets/images/Other/mountain-banner.png" />
    <hub-translate-loader [moduleName]="'Main'">
      <ng-template>
        <div class="ms-2 ms-md-4 ms-xxl-0">
          @if (validating) {
            <h4>{{ 'main.validatingellipsis' | translate }}</h4>
          } @else if (redirecting) {
            <h4>{{ 'main.redirectingellipsis' | translate }}</h4>
          } @else {
            @if (!showError) {
              <div>
                @switch (validationResult?.Status) {
                  @case (InvitationStatus.Expired) {
                    <h3>{{ 'main.invitationexpiredheader' | translate }} 🍃</h3>
                    <span class="landing-details">
                      <p
                        [innerHTML]="
                          'main.invitationexpireddetails'
                            | translate
                            | stringReplace: '{\{expirationDays}\}' : getExpirationDays().toString()
                        "></p>
                      <p [innerHTML]="'main.invitationproblemoptions' | translate"></p>
                      <p>
                        {{ 'main.invitationproblemfooter' | translate }}
                      </p>
                      <div class="button-container">
                        <s3-button
                          appearance="secondary"
                          class="landing-button"
                          (clicked)="contactAdmin()"
                          ><i class="fal fa-inbox"></i
                          >{{ 'main.contactadmin' | translate }}</s3-button
                        >
                      </div>
                    </span>
                  }
                  @case (InvitationStatus.Declined) {
                    <h3>{{ 'main.invitationdeclinedheader' | translate }} 🍃</h3>
                    <span class="landing-details">
                      <p [innerHTML]="'main.invitationdeclineddetails' | translate"></p>
                      <p [innerHTML]="'main.invitationproblemoptions' | translate"></p>
                      <p>
                        {{ 'main.invitationproblemfooter' | translate }}
                      </p>
                      <div class="button-container">
                        <s3-button
                          appearance="secondary"
                          class="landing-button"
                          (clicked)="contactAdmin()"
                          ><i class="fal fa-inbox"></i
                          >{{ 'main.contactadmin' | translate }}</s3-button
                        >
                      </div>
                    </span>
                  }
                  @default {
                    <h4>{{ 'main.invitationinvalid' | translate }}</h4>
                  }
                }
              </div>
            } @else {
              <h4>{{ 'main.invitationerror' | translate }}</h4>
            }
          }
          <s3-notification-toast
            #contactSuccess
            [message]="'main.contactadminsuccess' | translate"
            type="success"></s3-notification-toast>
          <s3-notification-toast
            #contactError
            [message]="'main.contactadminerror' | translate"
            type="error"></s3-notification-toast>
        </div>
      </ng-template>
    </hub-translate-loader>
  </div>
</div>
