import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SiteService } from './site.service';
import { IClient } from 'src/app/types';
import { CompanyService } from 'src/app/common';
import { TranslateService } from 'za-ui-controls';

@Component({
  selector: 's3-site-control',
  templateUrl: './site-control.component.html',
  styleUrls: ['./site-control.component.scss'],
})
export class SiteControlComponent implements OnInit {
  @Input() disabled = false;
  @Input() allSites = false;
  @Input() siteList: any;
  @Input() hasSiteList = false;
  @Input() selectedData: any;
  @Output() itemSelected = new EventEmitter();

  client?: IClient;
  clientName: string | undefined;
  subscription = '';
  defaultsiteID = -1;
  site: string = this.translateService.translate('shared.site');

  constructor(
    private siteservice: SiteService,
    private readonly clientService: CompanyService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.clientService.getCurrentClient().subscribe(value => {
      this.client = value;
      this.clientName = this.client?.ClientName;
      this.subscription =
        this.client?.Subscription && !this.client?.Subscription.IsBasic ? 'Standard' : 'Basic';
    });
    if (!this.hasSiteList) {
      this.siteservice.getSiteList().subscribe(data => {
        if (this.allSites) {
          const allSites = {
            SiteId: this.defaultsiteID,
            SiteName: this.translateService.translate('shared.allsites'),
          };
          this.siteList = [allSites].concat(data);
        } else {
          this.siteList = data;
        }
        this.selectedData = this.siteList[0];
        this.itemSelected.emit(this.selectedData);
      });
    }
  }

  itemSelect(item: any): void {
    this.selectedData = item;
    this.itemSelected.emit(item);
  }
}
