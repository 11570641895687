import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: environment.auth.signUpSignIn.name,
  },
  authorities: {
    signUpSignIn: {
      authority: environment.auth.signUpSignIn.authority,
    },
  },
  authorityDomain: environment.auth.authorityDomain,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.auth.authClientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: environment.auth.redirect, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: environment.auth.postLogoutRedirect,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  siiiCoreApi: {
    endpoint: environment.apiBaseUrl,
    scopes: environment.auth.scopes,
  },
  botApi: {
    endpoint: environment.botApiBaseUrl,
    scopes: environment.auth.scopes,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [], // If you would like the admin-user to explicitly consent via "Admin" page, instead of being prompted for admin consent during initial login, remove this scope.
  extraQueryParameters: { domain_hint: 'idms' },
};

export function getProtectedResourceMap(): Map<string, Array<string> | null> {
  const protectedResourceMap = new Map<string, Array<string> | null>();

  protectedResourceMap.set(
    `${protectedResources.siiiCoreApi.endpoint}/access-management/invitations/*/validate`,
    null,
  );

  protectedResourceMap.set(
    `${protectedResources.siiiCoreApi.endpoint}/access-management/invitations/*/decline`,
    null,
  );

  protectedResourceMap.set(
    `${protectedResources.siiiCoreApi.endpoint}/access-management/invitations/*/contact-admin`,
    null,
  );

  protectedResourceMap.set(
    protectedResources.siiiCoreApi.endpoint,
    protectedResources.siiiCoreApi.scopes,
  );

  protectedResourceMap.set(protectedResources.botApi.endpoint, protectedResources.botApi.scopes);

  return protectedResourceMap;
}
