import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnBoardingGuard } from './guards/onboarding.guard';
import { MsalGuard } from '@azure/msal-angular';
import { AdminGuard } from './guards/admin.guard';
import { InviteLandingComponent } from './visitor/invite-landing/invite-landing.component';

const routes: Routes = [
  {
    path: 'visitor',
    children: [
      {
        path: 'invite-landing',
        component: InviteLandingComponent,
      },
    ],
  },
  {
    path: 'onboarding',
    canActivate: [MsalGuard, OnBoardingGuard],
    loadChildren: () => import('./onboarding/onboarding.module').then(mod => mod.OnboardingModule),
  },
  {
    path: 'subscription',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./subscription/subscription.module').then(mod => mod.SubscriptionModule),
  },
  {
    path: 'checkout',
    canActivate: [MsalGuard],
    loadChildren: () => import('./checkout/checkout.module').then(mod => mod.CheckoutModule),
  },
  {
    path: 'home',
    canActivate: [MsalGuard, OnBoardingGuard],
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
  },
  {
    path: 'subscription',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./subscription/subscription.module').then(mod => mod.SubscriptionModule),
  },
  {
    path: 'admin',
    canActivate: [MsalGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
  },
  {
    path: 'terms-conditions',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./terms-and-conditions/terms-and-conditions.module').then(
        mod => mod.TermsAndConditionsModule,
      ),
  },
  {
    path: 'contact-provider/:providerId/:offerId',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./contact-provider/contact-provider.module').then(mod => mod.ContactProviderModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
