import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UIControlsModule } from '../ui-controls/ui.controls.module';

import { TranslateLoaderComponent } from './components/translate.loader.component';
import { SustainabilityConfig } from './models/sustainability-config.interface';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { RATranslatePipe } from './pipes/translate.pipe';
import { StringReplacePipe } from './pipes/string-replace.pipe';
import { ConfigService } from 'za-ui-controls';
import { MapBoxComponent } from './components/map-box/map-box.component';
import { TaskModalComponent } from './components/task-modal/task-modal.component';
import { RouterModule } from '@angular/router';
import { StandaloneHeaderComponent } from './components/standalone-header/standalone-header.component';
import { UiModule } from 'za-ui-controls';

@NgModule({
  imports: [HttpClientModule, CommonModule, UIControlsModule, RouterModule, UiModule],
  exports: [
    TranslateLoaderComponent,
    RATranslatePipe,
    DateDiffPipe,
    MapBoxComponent,
    StringReplacePipe,
    TaskModalComponent,
    StandaloneHeaderComponent,
  ],
  declarations: [
    TranslateLoaderComponent,
    RATranslatePipe,
    DateDiffPipe,
    MapBoxComponent,
    StringReplacePipe,
    TaskModalComponent,
    StandaloneHeaderComponent,
  ],
  providers: [],
})
export class SustainabilityCommonModule {
  static forRoot(config: SustainabilityConfig): ModuleWithProviders<SustainabilityCommonModule> {
    return {
      ngModule: SustainabilityCommonModule,
      providers: [ConfigService, { provide: 'config', useValue: config }],
    };
  }
}
