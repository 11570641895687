<div class="s3-dropdown">
  <div *ngIf="selectedData">
    <s3-text>{{ site }} #{{ selectedData.Rank }}</s3-text>
    <i class="fal fa-map-marker-alt mx-2"></i>
  </div>
  <div class="s3-site-control-dropdown">
    <s3-dropdown
      [items]="siteList"
      [ngModel]="selectedData"
      textField="SiteName"
      valueField="SiteId"
      (ngModelChange)="itemSelect($event)"
      size="sm"
      [disabled]="disabled"
      [valueTemplate]="valueTemplate"></s3-dropdown>
    <ng-template #valueTemplate let-site>
      <s3-text type="Large" color="var(--s3-color-secondary)">
        {{ site?.SiteName }}
      </s3-text>
    </ng-template>
  </div>
</div>
