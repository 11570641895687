import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'za-ui-controls';

@Pipe({
  name: 'translate',
})
export class RATranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * Translates the given key
   * @param key Key to translate
   */
  transform(key: string): string {
    return this.translateService.translate(key);
  }
}
