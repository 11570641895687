import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Injectable()
//This interceptor was created with the idea to cancel repeated requests caused by websocket sending multiple messages too quickly, which often occurs to mark all as read or delete all
export class CancelRepeatedRequestsInterceptor implements HttpInterceptor {
  private cache = new Map<string, Subject<void>>();

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check if the request URL contains "/notifications/"
    const url = request.url;
    if (
      request.method !== 'GET' ||
      (!url.includes('/notifications?Limit=') && !url.includes('/api/notifications/v1?UserId='))
    ) {
      return next.handle(request);
    }

    // check if the request is already cached
    const cachedResponse = this.cache.get(url);

    // cancel any previous requests
    if (cachedResponse) {
      cachedResponse.next();
    }

    const cancelRequests$ = new Subject<void>();

    // cache the new request , so that we can cancel it if needed.
    this.cache.set(url, cancelRequests$);

    const newRequest = next.handle(request).pipe(
      // cancel the request if a same request comes in.
      takeUntil(cancelRequests$),

      // complete the subject when the request completes.
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.delete(url);
        }
      }),
    );

    return newRequest;
  }
}
