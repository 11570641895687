import { Injectable, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { S3Constants, UserService } from '../common';
import { CacheService, LocaleService, ConfigService } from 'za-ui-controls';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  accessToken?: string;
  authComplete = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private configService: ConfigService,
    private localeService: LocaleService,
    private router: Router,
    private cacheService: CacheService,
  ) {}

  listenToAuthSubjects(): void {
    /**
     * Monitor for non-interactions to determine when flows is complete.
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        let activeAccount = this.msalService.instance.getActiveAccount();
        const allAccounts = this.msalService.instance.getAllAccounts();
        if (!activeAccount && allAccounts.length > 0) {
          this.msalService.instance.setActiveAccount(allAccounts[0]);
          activeAccount = this.msalService.instance.getActiveAccount();
        }
        this.msalService
          .acquireTokenSilent({
            scopes: environment.auth.scopes,
          })
          .subscribe(res => {
            this.accessToken = res.accessToken;
          });
        this.getUserInfo();
      });

    /**
     * Monitor for Account logged out in a different tab.
     */
    this.msalService.instance.enableAccountStorageEvents(); // This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalService
      .handleRedirectObservable()
      .pipe(takeUntil(this._destroying$))
      .subscribe(val => {});
  }

  logout(): void {
    this.cacheService.clear();
    window.open(environment.auth.postLogoutRedirect, '_self');
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  private getUserInfo(): void {
    this.userService.getUser().subscribe((user: any) => {
      this.configService.Config.localeCode = user.Locale;
      this.localeService.registerLocaleDataFromStorage().then(() => {
        this.logSession();
        this.authComplete = true;
        if (user.IsAdmin && !localStorage.getItem('impersonate')) {
          this.router.navigate(['/admin/impersonate']);
        } else if (window.location.pathname === environment.basePath) {
          this.router.navigate(['/home/dashboard']);
        }
      });
    });
  }

  // Log user session activity
  private logSession(): void {
    const isLogin = sessionStorage.getItem(S3Constants.IsLoggedIn);
    if (!isLogin) {
      const UserAgent = navigator.userAgent;
      this.userService.updateUserSessionActivity(UserAgent).subscribe((data: any) => {
        sessionStorage.setItem(S3Constants.IsLoggedIn, 'true');
      });
    }
  }
}
