import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClientService, TranslateService } from 'za-ui-controls';
import { ICurrency } from './currency.interface';
import { CacheStorageType } from '../sustainability-common/models/cache-storage-type.enum';

@Injectable({ providedIn: 'root' })
export class CommonService {
  triggerRequestDemo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly behavorialChanges = [
    {
      Image: 'assets' + '\\' + 'images' + '\\' + 'roadmap' + '\\' + 'power-roadmap.png',
      Title: 'machinerymaintenance',
      Description: 'machinerymaintenancedsc',
    },
    {
      Image: 'assets' + '\\' + 'images' + '\\' + 'roadmap' + '\\' + 'plug-roadmap.png',
      Title: 'standbypower',
      Description: 'standbypowerdsc',
    },
    {
      Image: 'assets' + '\\' + 'images' + '\\' + 'roadmap' + '\\' + 'thermostat-roadmap.png',
      Title: 'programmablethermostat',
      Description: 'programmablethermostatdsc',
    },
    {
      Image: 'assets' + '\\' + 'images' + '\\' + 'roadmap' + '\\' + 'boiler-roadmap.png',
      Title: 'boilersettings',
      Description: 'boilersettingsdsc',
    },
  ];

  constructor(
    private httpService: HttpClientService,
    private translateService: TranslateService,
  ) {}

  getDefaultTCversion(): Observable<{ Terms: any; TCKey: string }> {
    return this.httpService.get(environment.apiBaseUrl + '/common/tcversion');
  }

  getCurrency(): Observable<ICurrency[]> {
    return this.httpService.get(
      environment.apiBaseUrl + '/common/data/currency',
      true,
      CacheStorageType.window,
    );
  }

  getCodes(codSetName: string): Observable<any> {
    return this.httpService
      .get(
        environment.apiBaseUrl + '/common/codes?codesetName=' + codSetName,
        true,
        CacheStorageType.window,
      )
      .pipe(
        map((response: any[]) => {
          return response.map((item: any) => {
            return {
              ...item,
              DisplayText: this.translateService.translateSentence(
                item.CodeValue.replace(/[\s,\/()]+/g, ''),
                'Shared',
              ),
            };
          });
        }),
      );
  }

  getInvoiceBillDetails(clientDocumentInvoiceRqst: any): Observable<any> {
    const queryParams: string[] = [];

    if (clientDocumentInvoiceRqst.invoiceId) {
      queryParams.push(`invoiceId=${clientDocumentInvoiceRqst.invoiceId}`);
    }

    if (clientDocumentInvoiceRqst.siteId) {
      queryParams.push(`siteId=${clientDocumentInvoiceRqst.siteId}`);
    }

    if (clientDocumentInvoiceRqst.startDt) {
      queryParams.push(`startDate=${clientDocumentInvoiceRqst.startDt}`);
    }

    if (clientDocumentInvoiceRqst.endDt) {
      queryParams.push(`endDate=${clientDocumentInvoiceRqst.endDt}`);
    }

    const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    const url = `${environment.apiBaseUrl}/Invoice${queryString}`;
    return this.httpService.get(url);
  }

  uploadAndGetInvoiceData(files: File[]): Observable<any> {
    const observablesArray: Observable<any>[] = files.map((file: File) => {
      const formData: FormData = new FormData();
      formData.append('formFile', file);
      return this.httpService.postFile(environment.apiBaseUrl + '/Invoice', formData);
    });
    return forkJoin(observablesArray);
  }

  getEmissionType(): Observable<any> {
    return of([
      {
        EmissionTypesId: 1,
        EmissionType: 'Market based emissions',
      },
    ]);
  }

  getUOMsByDatastream(dataStreamName: string): Observable<any> {
    return this.httpService
      .get(environment.apiBaseUrl + '/datastream/uombyname?datastreamName=' + dataStreamName)
      .pipe(
        map((response: any[]) => {
          return response.map((item: any) => {
            return {
              ...item,
              DisplayText: this.translateService.translate('shared.' + item.UomNameKey),
            };
          });
        }),
      );
  }

  getStateByCountry(countryId: string): Observable<any[]> {
    const url = `${environment.apiBaseUrl}/common/data/states?countryId=${countryId}`;
    return this.httpService.get(url);
  }

  getTaxIdCountryRegex(countryId: number): Observable<any> {
    const url = `${environment.apiBaseUrl}/common/taxIdCountryRegex?countryId=${countryId}`;
    return this.httpService.get(url);
  }

  getUOMs(datastreamMeasurementId: number): Observable<any> {
    return this.httpService
      .get(
        environment.apiBaseUrl + '/track/uoms?datastreamMeasurementId=' + datastreamMeasurementId,
      )
      .pipe(
        map((response: any[]) => {
          return response.map((item: any) => {
            return {
              ...item,
              DisplayText: this.translateService.translate('shared.' + item.UomNameKey),
            };
          });
        }),
      );
  }

  getCountry(): Observable<any[]> {
    return this.httpService.get(environment.apiBaseUrl + '/common/data/country').pipe(
      map((response: any[]) => {
        return response.map((item: any) => {
          return {
            ...item,
            DisplayText: this.translateService.translateSentence(
              item.CountryName.replace(/[\s,\/()]+/g, ''),
              'Shared',
            ),
          };
        });
      }),
    );
  }

  getOwnership(): Observable<any[]> {
    return this.httpService.get(environment.apiBaseUrl + '/common/data/ownership');
  }

  getSiteSubtypes(): Observable<any[]> {
    return this.httpService.get(environment.apiBaseUrl + '/common/data/sitesubtypes').pipe(
      map((response: any[]) => {
        return response.map((item: any) => {
          return {
            ...item,
            DisplayText: this.translateService.translate('Shared.' + item.SiteSubTypeNameKey),
          };
        });
      }),
    );
  }

  getSizeUOMs(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/common/sizeUOM');
  }

  acceptTC(moveToHistory = false): Observable<any> {
    return this.httpService.put(environment.apiBaseUrl + '/user/tc/accept', moveToHistory);
  }

  getUserLocale(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/common/UserLocale');
  }

  getLanguages(codesetName: string): Observable<any> {
    return this.httpService.get(
      environment.sustainabilityHubBaseUrl + 'api/common/codes?codesetName=' + codesetName,
    );
  }

  updateLanguageCd(codeId: number): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/common/Languagecd', codeId);
  }

  getTCinformation(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/common/TcversionInfo');
  }

  deleteMeasurement(measurementValueId: number): Observable<any> {
    return this.httpService.delete(
      environment.apiBaseUrl + '/track/measurementValue?measurementValueId=' + measurementValueId,
    );
  }

  deleteInvoice(clientDocumentId: number): Observable<any> {
    return this.httpService.delete(environment.apiBaseUrl + '/Invoice/' + clientDocumentId);
  }

  getUomsByUomNames(uomNames: string[]): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/common/uoms', uomNames).pipe(
      map((response: any[]) => {
        return response.map((item: any) => {
          return {
            ...item,
            DisplayText: this.translateService.translate('shared.' + item.UomNameKey),
          };
        });
      }),
    );
  }
}
