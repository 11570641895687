import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'za-ui-controls';
import { differenceInMonths, differenceInDays } from 'date-fns';

@Pipe({
  name: 'date_diff',
})
export class DateDiffPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * Calculates the difference from the given date to today. Will output in a human friendly way.
   * i.e. "3 months", "16 days", "today"
   * @param date to compare distance from today
   */
  transform(date: Date): string {
    const now = new Date();
    const months = differenceInMonths(date, now);
    if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''}`;
    }
    const days = differenceInDays(date, now);
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    }
    return 'today';
  }
}
