import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 's3-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
})
export class TaskModalComponent {
  @Input() taskname: any;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
  ) {}

  onClose(): void {
    this.activeModal.dismiss();
  }

  navigateToDashboard(): void {
    this.activeModal.dismiss();
    this.router.navigate(['/home/dashboard']);
  }
}
