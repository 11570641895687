import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  constructor() {}

  setSessionCookie(name: string): void {
    this.setCookie(name, true, 0);
  }

  getCookieValue(name: string): string | undefined {
    const b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  private setCookie(cname: string, cvalue: string | boolean, exdays: number): void {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    let cookie = cname + '=' + cvalue + ';';
    if (exdays > 0) cookie = cookie + expires + ';';
    cookie = cookie + 'path=/';
    document.cookie = cookie;
  }
}
