const basePath = '/dev/siii/';

export const environment = {
  environmentName: 'dev',
  production: true,
  sustainabilityHubBaseUrl: 'https://activate-dev.zeigo.com/hub/dev/api/',
  apiBaseUrl: 'https://activate-dev.zeigo.com/dev/siii-core/api',
  notificationsBaseUrl:
    'https://dev-app.resourceadvisor.schneider-electric.com/notifications/nonprod/api',
  notificationAppKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbiI6InplaWdvYWN0aXZhdGVfZGV2ZWxvcCIsImV4cCI6MTczNjY5NjY2MSwiaXNzIjoiU0UuU3VzdGFpbmFiaWxpdHkuUGxhdGZvcm0uTm90aWZpY2F0aW9ucy1ub25wcm9kIiwiYXVkIjoiU0UuU3VzdGFpbmFiaWxpdHkuUGxhdGZvcm0uTm90aWZpY2F0aW9ucy1ub25wcm9kIn0.Q4WMFJ_XBTT7zmyt4O_nhCBhlweFuTK6kiPmbvegEqU',
  botApiBaseUrl: 'https://activate-dev.zeigo.com/copilot/api',
  zenDeskLink: 'https://activate-zeigo.zendesk.com',
  basePath,
  auth: {
    authClientId: '26e91faf-19d2-44b6-9c0a-2899e03df68a',
    signUpSignIn: {
      name: 'B2C_1A_SIGNIN',
      authority: 'https://subdevb2c.b2clogin.com/subdevb2c.onmicrosoft.com/B2C_1A_SIGNIN',
    },
    authorityDomain: 'subdevb2c.b2clogin.com',
    redirect: basePath,
    postLogoutRedirect:
      'https://se--uatbfo22.sandbox.my.site.com/identity/IDMS_IDPSloInit?post_logout_redirect_uri=https%3A%2F%2Fsubdevb2c.b2clogin.com%2Fsubdevb2c.onmicrosoft.com%2FB2C_1A_SIGNIN%2Foauth2%2Fv2.0%2Flogout%3Fpost_logout_redirect_uri%3Dhttps%3A%2F%2Factivate-dev.zeigo.com%2Fdev%2Fsiii%2Fhome%2Fdashboard&app=ProjectS3',
    scopes: ['https://subdevb2c.onmicrosoft.com/siii-core-api/siii-core-api'],
    changePasswordURL:
      'https://se--uatbfo22.sandbox.my.site.com/identity/idmspasswordupdate?app=ProjectS3',
  },
  logForNonProd: (s: string): void => {
    console.log(s);
  },
  bot: 'ZA',
  mapboxAccessToken:
    'pk.eyJ1Ijoic2NobmVpZGVyZXNzbm9ucHJvZCIsImEiOiJja2ZwczJmY2EyOTY2MnNtang4bmU0ZW11In0.76yWkJ8VDCn83AmyLsFSNg',
  mapboxUrl: 'https://api.mapbox.com/search-js/v1.0.0-beta.14/web.js',
};
