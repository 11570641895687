import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IAddress } from '../../models/address.interface';
import { environment } from 'src/environments/environment';

declare let mapboxsearch: any; //mapbox is loaded dynamically

@Component({
  selector: 's3-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss'],
})
export class MapBoxComponent implements OnDestroy {
  @Output() newAddress = new EventEmitter<IAddress>();

  readonly mapBoxScriptId: string = 'mapbox-component';
  private _initialized: boolean = false;

  @Input()
  set initialize(value: boolean) {
    if (value === true && this.initialize === false) {
      this.initializeMapbox();
      this._initialized = value;
    }
  }

  get initialize(): boolean {
    return this._initialized;
  }

  constructor() {}

  ngOnDestroy(): void {
    const scriptNode = document.getElementById(this.mapBoxScriptId);
    scriptNode?.remove();
  }

  initializeMapbox(): void {
    if (document.querySelectorAll('[autocomplete="address-line1"]').length == 0) {
      console.warn('no element found with attribute of autocomplete="address-line1');
    }

    const node = document.createElement('script');
    node.src = environment.mapboxUrl;
    node.id = this.mapBoxScriptId;
    node.type = 'text/javascript';
    node.async = false;
    node.onload = (): void => {
      console.log(`map box loaded: ${mapboxsearch}`);
      const autofill = mapboxsearch.autofill({
        accessToken: environment.mapboxAccessToken,
      });

      autofill.addEventListener('retrieve', (event: any) => {
        this.newAddress.emit({
          City: event.detail.features[0].properties.address_level2,
          StateName: event.detail.features[0].properties.region,
          StateCode: event.detail.features[0].properties.region_code,
          ZipCode: event.detail.features[0].properties.postcode,
          AddressLine1: event.detail.features[0].properties.address_line1,
          CountryName: event.detail.features[0].properties.country,
          CountryCode: event.detail.features[0].properties.country,
          AddressLine2: '',
          FirstName: '',
          LastName: '',
          Phone: '',
          EmailAddress: '',
          Coordinates: event.detail.features[0].geometry.coordinates,
        } as IAddress);
      });
    };
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
